import { Container } from './ServiceCardStyles';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import Icon_Outfit from '../../assets/icons/outfit.png';
import Icon_Furniture from '../../assets/icons/furniture.png';
import Icon_Music from '../../assets/icons/music.png';
import Icon_Tree from '../../assets/icons/tree.png';
import Icon_User_Card from '../../assets/icons/user_card.png';
import Icon_Scan from '../../assets/icons/scan.png';
import Icon_CheckName from '../../assets/icons/checknames.png';
import Icon_Posts from '../../assets/icons/posts.png';
import Icon_Friends from '../../assets/icons/friends.png';
import Icon_Cost from '../../assets/icons/cost.png';
import Icon_Store from '../../assets/icons/store.png';
import Icon_House from '../../assets/icons/house.png';
import Icon_Friends_Code from '../../assets/icons/friendscode.png';
import Icon_Password from '../../assets/icons/password.png';
import Icon_SymbolCreator from '../../assets/icons/symbol.png';
import Icon_Favorite from '../../assets/icons/favorite.png';
import Icon_Spy from '../../assets/icons/spy.png';
import Icon_Shop from '../../assets/icons/store.png';
import Icon_Robot from '../../assets/icons/robot.png';
import Icon_Protection from '../../assets/icons/protection.png';
import Icon_Rewards from '../../assets/icons/rewards.png';

import toast from 'react-hot-toast';
import { useContext } from 'react';
import { AuthContext } from '../../contexts/AuthContexts';

export interface ServiceCardProps {
  id: number;
  title: string;
  description: string;
  enabled?: boolean;
  maintenance?: boolean;
  icon:
    | 'outfit'
    | 'furniture'
    | 'music'
    | 'tree'
    | 'user_card'
    | 'scan'
    | 'checkname'
    | 'posts'
    | 'friends'
    | 'cost'
    | 'store'
    | 'catalog'
    | 'house'
    | 'friends_code'
    | 'password'
    | 'symbol'
    | 'favorite'
    | 'spy'
    | 'protection'
    | 'rewards'
    | 'robot';
  path: string;
}

export function ServiceCard({
  title,
  description,
  icon,
  path,
  enabled,
  maintenance,
}: ServiceCardProps) {
  const { t } = useTranslation();

  const navigate = useNavigate();

  const { user } = useContext(AuthContext);

  function showIcon() {
    if (icon === 'outfit') {
      return Icon_Outfit;
    }
    if (icon === 'furniture') {
      return Icon_Furniture;
    }
    if (icon === 'music') {
      return Icon_Music;
    }
    if (icon === 'tree') {
      return Icon_Tree;
    }
    if (icon === 'user_card') {
      return Icon_User_Card;
    }
    if (icon === 'scan') {
      return Icon_Scan;
    }
    if (icon === 'checkname') {
      return Icon_CheckName;
    }
    if (icon === 'posts') {
      return Icon_Posts;
    }
    if (icon === 'friends') {
      return Icon_Friends;
    }
    if (icon === 'cost') {
      return Icon_Cost;
    }
    if (icon === 'store' || icon === 'catalog') {
      return Icon_Store;
    }
    if (icon === 'house') {
      return Icon_House;
    }
    if (icon === 'friends_code') {
      return Icon_Friends_Code;
    }
    if (icon === 'password') {
      return Icon_Password;
    }
    if (icon === 'symbol') {
      return Icon_SymbolCreator;
    }
    if (icon === 'favorite') {
      return Icon_Favorite;
    }
    if (icon === 'spy') {
      return Icon_Spy;
    }
    if (icon === 'protection') {
      return Icon_Protection;
    }
    if (icon === 'robot') {
      return Icon_Robot;
    }
    if (icon === 'rewards') {
      return Icon_Rewards;
    }
  }

  function navigateToPage() {
    // const private_rooms = ['/room-furniture', '/friend-gift'];

    if (enabled === false) return;

    if (
      (path === '/room-furniture' ||
        path === '/media-player' ||
        path === '/feed-sort' ||
        path === '/historical-room-viewer' ||
        path === '/avatar-wishlist' ||
        path === '/history-rooms' ||
        path === '/history-outfit' ||
        path === '/private-room' ||
        path === '/hidden-outfit-viewer' ||
        path === '/hidden-current-outfit' ||
        path === '/private-room-history' ||
        path === '/scan-classic-rooms' ||
        path === '/scan-live-rooms' ||
        path === '/secret-santa' ||
        path === '/profile-outfit' ||
        path === '/avatar-card' ||
        path === '/show-room' ||
        path === '/my-friend' ||
        path === '/user-protection') &&
      !user
    ) {
      toast.error(t('alert_logged'));
      navigate('/sign-in');
      return;
    }

    navigate(path);
  }

  return (
    <Container>
      <div className="imgArea">
        <img src={showIcon()} alt={icon} />
        <button
          style={{
            opacity: enabled === false ? 0.7 : 1,
            cursor: enabled === false ? 'default' : 'pointer',
          }}
          onClick={() => navigateToPage()}
        >
          {enabled === false
            ? maintenance
              ? t('btn_maintenance')
              : t('btn_shortly')
            : t('btn_access')}
        </button>
      </div>
      <div>
        <h3>{t(title)}</h3>
        <p>
          {t(description)}
          {maintenance && ` ${t('maintenance_service')}`}
        </p>
        {/* {path === '/private-room' && (
          <p className="token">* 1 {t('token_use_empty')}</p>
        )}
        {path === '/feed-sort' && (
          <p className="token">* {t('token_use_more')}</p>
        )} */}
      </div>
    </Container>
  );
}

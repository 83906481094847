import { useContext } from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';

import { Private } from './Private';
import { AuthContext } from '../contexts/AuthContexts';

import { Home } from '../pages/Home/Home';
import { RoomFurniture } from '../pages/RoomFurniture/RoomFurniture';
import { ProfileOutfit } from '../pages/ProfileOutfit/ProfileOutfit';
import { AvatarCard } from '../pages/AvatarCard/AvatarCard';
import { CurrentSong } from '../pages/CurrentSong/CurrentSong';
import { SignIn } from '../pages/SignIn/SignIn';
import { SignUp } from '../pages/SignUp/SignUp';
import { Account } from '../pages/Account/Account';
import { Plans } from '../pages/Plans/Plans';
import { GetRoom } from '../pages/GetRoom/GetRoom';
import { SantaRoom } from '../pages/SantaRoom/SantaRoom';
// import { PasswordGenerator } from '../pages/PasswordGenerator/PasswordGenerator';
// import { ProductTree } from '../pages/ProductTree/ProductTree';
// import { CheckName } from '../pages/CheckName/CheckName';
// import { WishList } from '../pages/WishList/WishList';
import { MyFriendIs } from '../pages/MyFriendIs/MyFriendIs';
import { TermsOfServices } from '../pages/TermsOfServices/TermsOfServices';
import { Contact } from '../pages/Contact/Contact';
import { About } from '../pages/About/About';
import { CurrentOutfit } from '../pages/CurrentOutfit/CurrentOutfit';
// import { ClassicRoomScanner } from '../pages/ClassicRoomScanner/ClassicRoomScanner';
// import { LiveRoomScanner } from '../pages/LiveRoomScanner/LiveRoomScanner';
import { HistoryClassicRoom } from '../pages/HistoryClassicRoom/HistoryClassicRoom';
import { HistoryUserOutfit } from '../pages/HistoryUserOutfit/HistoryUserOutfit';
import { ResetPassword } from '../pages/ResetPassword/ResetPassword';
import { ProtectionUser } from '../pages/ProtectionUser/ProtectionUser';
import { InspectClassicRoom } from '../pages/InspectClassicRoom/InspectClassicRoom';
import { InspectLiveRoom } from '../pages/InspectLiveRoom/InspectLiveRoom';
import { InspectPhoto } from '../pages/InspectPhoto/InspectPhoto';
// import { HistoryPrivateRoom } from '../pages/HistoryPrivateRoom/HistoryPrivateRoom';
// import { PrivateRoom } from '../pages/PrivateRoom/PrivateRoom';
// import { FeedSort } from '../pages/FeedSort/FeedSort';

export function RoutesApp() {
  const { signed } = useContext(AuthContext);

  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/plans" element={<Plans />} />
      {/* <Route path="/password-generator" element={<PasswordGenerator />} /> */}
      {/* <Route path="/derivable-tree" element={<ProductTree />} /> */}
      {/* <Route path="/check-name" element={<CheckName />} /> */}
      {/* <Route path="/avatar-wishlist" element={<WishList />} /> */}
      <Route path="/terms-of-services" element={<TermsOfServices />} />
      <Route path="/contact" element={<Contact />} />
      <Route path="/about" element={<About />} />
      <Route path="/reset-password" element={<ResetPassword />} />
      {!signed && (
        <>
          <Route path="/sign-in" element={<SignIn />} />
          <Route path="/sign-up" element={<SignUp />} />
        </>
      )}
      {/* PRIVATE */}
      {/* <Route path="room-furniture" element={<RoomFurniture />} /> */}
      <Route
        path="/room-furniture"
        element={
          <Private>
            <RoomFurniture />
          </Private>
        }
      />
      {/* <Route path="media-player" element={<CurrentSong />} /> */}
      <Route
        path="/media-player"
        element={
          <Private>
            <CurrentSong />
          </Private>
        }
      />
      <Route
        path="/history-rooms"
        element={
          <Private>
            <HistoryClassicRoom />
          </Private>
        }
      />
      <Route
        path="/history-outfit"
        element={
          <Private>
            <HistoryUserOutfit />
          </Private>
        }
      />
      {/* <Route
        path="/private-room-history"
        element={
          <Private>
            <HistoryPrivateRoom />
          </Private>
        }
      /> */}
      {/* <Route
        path="/private-room"
        element={
          <Private>
            <PrivateRoom />
          </Private>
        }
      /> */}
      {/* <Route path="scan-classic-rooms" element={<ClassicRoomScanner />} /> */}
      {/* <Route
        path="/scan-classic-rooms"
        element={
          <Private>
            <ClassicRoomScanner />
          </Private>
        }
      /> */}
      {/* <Route path="scan-live-rooms" element={<LiveRoomScanner />} /> */}
      {/* <Route
        path="/scan-live-rooms"
        element={
          <Private>
            <LiveRoomScanner />
          </Private>
        }
      /> */}
      {/* <Route path="hidden-current-outfit" element={<CurrentOutfit />} /> */}
      <Route
        path="/hidden-current-outfit"
        element={
          <Private>
            <CurrentOutfit />
          </Private>
        }
      />
      <Route
        path="/secret-santa"
        element={
          <Private>
            <SantaRoom />
          </Private>
        }
      />
      <Route
        path="/user-protection"
        element={
          <Private>
            <ProtectionUser />
          </Private>
        }
      />
      <Route
        path="/account"
        element={
          <Private>
            <Account />
          </Private>
        }
      />
      <Route
        path="/profile-outfit"
        element={
          <Private>
            <ProfileOutfit />
          </Private>
        }
      />
      <Route
        path="/avatar-card"
        element={
          <Private>
            <AvatarCard />
          </Private>
        }
      />
      {/* <Route
        path="/feed-sort"
        element={
          <Private>
            <FeedSort />
          </Private>
        }
      /> */}
      <Route
        path="/show-room"
        element={
          <Private>
            <GetRoom />
          </Private>
        }
      />
      <Route
        path="/inspect-classic-room"
        element={
          <Private>
            <InspectClassicRoom />
          </Private>
        }
      />
      <Route
        path="/inspect-live-room"
        element={
          <Private>
            <InspectLiveRoom />
          </Private>
        }
      />
      <Route
        path="/inspect-photo"
        element={
          <Private>
            <InspectPhoto />
          </Private>
        }
      />
      <Route
        path="/my-friend"
        element={
          <Private>
            <MyFriendIs />
          </Private>
        }
      />

      {/* GENERAL 404 PAGES */}
      <Route path="/*" element={<Navigate to="/" />} />
    </Routes>
  );
}

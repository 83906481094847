import { useTranslation } from 'react-i18next';
import { Container } from './PlansStyles';
import { useContext, useEffect, useState } from 'react';
import { AuthContext } from '../../contexts/AuthContexts';
// import { setupAPIClient } from '../../services/api';
// import { getStripeJs } from '../../services/stripe-js';
import { parseCookies } from 'nookies';
import toast from 'react-hot-toast';
import { useLocation, useNavigate } from 'react-router-dom';
import { api } from '../../services/apiClient';

// NO MESMO URL DA API PARA VER AS POSTAGENS DETALHES: TEM PHOTO_DETAILS QUE
// MOSTRAR TODOS OS USERS DA SALA E PRODUTOS DE QUANDO FOI TIRADA A FOTO

export function Plans() {
  const { user, signed, getMeStatusDetail } = useContext(AuthContext);
  const { t } = useTranslation();
  const location = useLocation();

  const [premium, setPremium] = useState(false);
  const [plan, setPlan] = useState('');

  const navigate = useNavigate();

  useEffect(() => {
    getInfo();
  }, [user]);

  async function getInfo() {
    if (!signed) {
      setPremium(false);
      return;
    }

    let currentPlan = '';
    const res = {
      status: user?.subscriptions?.status === 'active' ? true : false,
      plan: user?.subscriptions?.plan,
    };
    setPremium(res?.status);
    if (res?.plan && res?.plan !== '') {
      currentPlan = res?.plan === 'premium' ? 'Premium' : 'Premium Plus';
    }
    setPlan(currentPlan);
  }

  // async function handleGoPremium(type: 'premium' | 'premium_plus') {
  //   if (!signed) {
  //     toast.error(t('alert_logged'));
  //     navigate('/sign-in');
  //     return;
  //   }

  //   if (premium) return;

  //   try {
  //     const apiClient = setupAPIClient();
  //     const { '@searchvu.token': token } = parseCookies();

  //     const response = await apiClient.post(
  //       '/subscribe',
  //       {
  //         type: type,
  //       },
  //       {
  //         headers: {
  //           Authorization: `Bearer ${token}`,
  //         },
  //       }
  //     );

  //     const { sessionId } = response.data;

  //     const stripe = await getStripeJs();

  //     await stripe.redirectToCheckout({ sessionId: sessionId });
  //   } catch (error) {
  //     //console.log('ERROR: ', error);
  //   }
  // }

  // async function handleCreatePortal() {
  //   try {
  //     if (!premium) return;

  //     const apiClient = setupAPIClient();
  //     const { '@searchvu.token': token } = parseCookies();

  //     const response = await apiClient.post('/create-portal', {
  //       headers: {
  //         Authorization: `Bearer ${token}`,
  //       },
  //     });

  //     const { sessionId } = response.data;

  //     window.location.href = sessionId;
  //   } catch (error) {
  //     //console.log('ERROR: ', error);
  //   }
  // }

  // useEffect(() => {
  //   if (!user) return;

  //   if (
  //     !document.querySelector(
  //       'script[src="https://js.chargebee.com/v2/chargebee.js"]'
  //     )
  //   ) {
  //     const script = document.createElement('script');
  //     script.src = 'https://js.chargebee.com/v2/chargebee.js';
  //     script.async = true;
  //     script.dataset.cbSite = 'explorevu-test';

  //     document.head.appendChild(script);

  //     return () => {
  //       document.head.removeChild(script);
  //     };
  //   }
  // }, [user]);

  const handleCreatePortal = () => {
    if (!premium) return;

    window.location.href = 'https://consumer.hotmart.com/';
  };

  const handleGoPremium = async (type: 'premium' | 'premium_plus') => {
    if (!signed) {
      toast.error(t('alert_logged'));
      navigate('/sign-in');
      return;
    }

    if (premium) return;

    const usernameEncoded = encodeURIComponent(`${user.name} ExploreVu`);

    const checkoutUrl = `https://pay.hotmart.com/X95868712B?checkoutMode=10&sck=${user.id}&name=${usernameEncoded}&email=${user.email}`;

    window.location.href = checkoutUrl;
  };

  // const handleGoPremium = async (type: 'premium' | 'premium_plus') => {
  //   if (!signed) {
  //     toast.error(t('alert_logged'));
  //     navigate('/sign-in');
  //     return;
  //   }

  //   if (premium) return;

  //   try {
  //     const { '@searchvu.token': token } = parseCookies();

  //     const response = await api.post(
  //       '/subscribe',
  //       {},
  //       {
  //         headers: {
  //           Authorization: `Bearer ${token}`,
  //         },
  //       }
  //     );

  //     const checkoutUrl = response.data?.checkout_url;
  //     if (!checkoutUrl) {
  //       toast.error('Erro ao iniciar checkout...');
  //       return;
  //     }

  //     window.location.href = checkoutUrl;
  //   } catch (error) {
  //     console.error('Erro ao criar sessão de checkout:', error);
  //     toast.error('Erro ao criar sessão de checkout.');
  //   }
  // };

  return (
    <Container>
      <div className="title-area">
        <h2>{t('plans_title')}</h2>
        <span></span>
      </div>

      {location.state?.premiumFunctionality && (
        <h3 className="alertSubscription">{t('alert_subscription')}</h3>
      )}
      {location.state?.premiumPlusFunctionality && (
        <h3 className="alertSubscription">{t('alert_subscription_plus')}</h3>
      )}
      <h4>{t('plans_subtitle')}</h4>
      <p className="description">{t('plans_premium_description')}</p>

      <p className="description" style={{ marginBottom: 60 }}>
        {t('currency_information')}
      </p>

      <div className="plansArea">
        <div className="card">
          <h3>{t('plans_plan_free')}</h3>
          <ul>
            <li>{t('plans_no_ads')}</li>
            <li>{t('service_friend_code_title')}</li>
            <li>{t('service_avatarcard_title')}</li>
            <li>{t('service_profileoutfit_title')}</li>
            {/* <li>{t('service_wishlist_title')}</li> */}
            <li>{t('service_getroom_title')}</li>
            {/* <li>{t('service_name_check_title')}</li> */}
            {/* <li>
              {t('service_lucky_post_title')}
              <span className="obs">({t('token_use_more')})</span>
            </li> */}
            <li>{t('service_inspect_classic_room')}</li>
            <li>{t('service_derivabletree_title')}</li>
            {/* <li>
              {t('service_private_room_title')}
              <span className="obs">({t('token_use')})</span>
            </li> */}
            {/* <li>*** {t('token_buy')}</li> */}
          </ul>
        </div>
        {/*  */}
        <div className="card">
          <h3 className="premium">{t('plans_plan_vip')}</h3>
          <ul>
            <li>{t('service_furniture_title')}</li>
            <li>{t('service_song_title')}</li>
            <li>{t('service_current_outift_title')}</li>
            <li>{t('service_inspect_live_room')}</li>
            <li>{t('service_inspect_photo')}</li>
            <li>{t('service_history_room_title')}</li>
            <li>{t('service_history_outfit_title')}</li>
            <li>{t('service_friend_title')}</li>
            <li>
              {t('plan_premium_hidden_room')}
              <span className="obs">
                ({t('plan_premium_hidden_history_room')})
              </span>
            </li>
            <li>
              {t('plan_premium_hidden_outfit')}
              <span className="obs">
                ({t('plan_premium_hidden_history_outift')})
              </span>
            </li>
            <li>** {t('token_month')}</li>
            {/* <li>*** {t('token_buy_premium')}</li> */}
            <li>
              <b>+ {t('token_redeem')}</b>
            </li>
            <li>
              <b>+ {t('plans_all_free')}</b>
            </li>
            <li>
              <b>+ {t('plans_updates')}</b>
            </li>
            <li>
              <b>+ {t('plans_support')}</b>
            </li>
          </ul>
          <div className="priceArea">
            <p>
              <span>{t('plans_premium_price_from')} $14.99</span>{' '}
              {t('plans_premium_price_per')}
            </p>
            <span className="total">$7</span>
          </div>
          <button
            onClick={() => handleGoPremium('premium')}
            disabled={premium}
            style={{
              background: premium ? 'transparent' : '#f49c14',
              color: premium ? '#f49c14' : '#fff',
              cursor: premium ? 'default' : 'pointer',
            }}
          >
            {plan === 'Premium'
              ? t('plans_plan_is_premium')
              : t('plans_plan_button')}
          </button>
          {premium && (
            <button className="changeBtn" onClick={() => handleCreatePortal()}>
              {t('plans_plan_change_plan')}
            </button>
          )}
        </div>
        {/*  */}
        {/* <div className="card">
          <h3 className="premium">{t('plans_plan_vip_plus')}</h3>
          <ul>
            <li>{t('catalog_title')}</li>
            <li>
              * {t('service_spy_user_title')}
              <span className="obs">({t('service_apy_user_private_obs')})</span>
            </li>
            <li>
              * {t('service_spy_user_title_email')}
              <span className="obs">
                ({t('service_apy_user_notification_obs')})
              </span>
            </li>
            <li>** {t('token_month_plus')}</li>
            <li>*** {t('token_buy_premium')}</li>
            <li>
              <b>+ {t('plans_all_free')}</b>
            </li>
            <li>
              <b>+ {t('plans_all_premium')}</b>
            </li>
            <li>
              <b>+ {t('plans_updates')}</b>
            </li>
            <li>
              <b>+ {t('plans_support')}</b>
            </li>
          </ul>
          <div className="priceArea">
            <p>
              <span>{t('plans_premium_price_from_plus')} $29.99</span>{' '}
              {t('plans_premium_price_per')}
            </p>
            <span className="total">$15</span>
          </div>
          <button
            onClick={() => handleGoPremium('PREMIUM PLUS BTN')}
            disabled={premium}
            style={{
              background: premium ? 'transparent' : '#f49c14',
              color: premium ? '#f49c14' : '#fff',
              cursor: premium ? 'default' : 'pointer',
            }}
          >
            {plan === 'Premium Plus'
              ? t('plans_plan_is_premium_plus')
              : t('plans_plan_button_plus')}
          </button>
          {premium && (
            <button className="changeBtn" onClick={() => handleCreatePortal()}>
              {t('plans_plan_change_plan')}
            </button>
          )}
          </div> */}
      </div>

      <div className="disclaimer">
        {/* <p>* {t('service_spy_rooms_private_obs')}</p> */}
        <p>** {t('token_disclaimer')}</p>
        <p>*** {t('token_disclaimer_buy')}</p>
      </div>
    </Container>
  );
}
